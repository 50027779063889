import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:7040'
      : window.location.origin;
  const apiCall = useCallback(
    async ({ url, method = 'GET', body = null, onSuccess = () => {} }) => {
      setIsLoading(true);
      url = `${baseUrl}${url}`;
      const token = await getAccessTokenSilently();
      const options = {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: typeof body === 'string' ? body : body !== null ? JSON.stringify(body) : null,
      };

      return fetch(url, options).then(async res => {
        setIsLoading(false);
        if (res.status >= 400) {
          const errorResponse = await res.json();
          const error = errorResponse.errors[0];

          if (error.code === 'user_blocked') {
            const url = 'you-are-banned';
            if (window.location.pathname !== `/${url}`)
              window.location.href = url;
          } else
            toast({
              description: error.message,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'bottom',
              bg: 'brand.600',
            });
          return errorResponse;
        } else {
          onSuccess();

          if (method === 'GET' && res.status !== 204) {
            const data = await res.json();
            return data;
          } else {
            return '';
          }
        }
      });
    },
    [getAccessTokenSilently, baseUrl, toast]
  );

  return { apiCall, isLoading };
};

export default useApi;
