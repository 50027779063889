import React, { useEffect, useState, useCallback } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Box,
  Button,
  Heading,
  HStack,
  useTheme,
  VStack,
  Input,
  Container,
} from '@chakra-ui/react';
import useApi from '../hooks/useApi';
import { toDateStr } from '../helpers/dateHelpers';
import { CustomCalendar } from '../components/CustomCalendar';
import { useToast } from '@chakra-ui/react';

const oneDayMs = 86400000;

export const HomePage = () => {
  const toast = useToast();
  const theme = useTheme();
  const { apiCall } = useApi();
  const [data, setData] = useState([]);
  const fetchData = useCallback(async () => {
    const data = await apiCall({ url: '/parking-slots' });
    setData(data);
  }, [apiCall]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const mapSlot = slot => {
    let event = { date: toDateStr(slot.date), display: 'background' };
    if (slot.bookedPlaceByUser != null) {
      event.title = `${slot.bookedPlaceByUser} - Your Booked Place Number `;
      event.color = theme.colors.orange['400'];
      event.isBooked = true;
    } else {
      event.title = `Avaliable - ${slot.count}`;
    }

    const slotDate = new Date(slot.date).setHours(0, 0, 0, 0);
    const currentDate = new Date().setHours(0, 0, 0, 0);
    if (slotDate < currentDate) {
      event.color = 'gray';
    }

    return event;
  };

  const fetchTimeData = useCallback(async () => {
    const data = await apiCall({ url: `/time` });
    setReleaseTime(new Date(data.releaseTime));
  }, [apiCall]);

  useEffect(() => {
    fetchTimeData();
  }, [fetchTimeData]);

  const onAction = async (book = true) => {
    const body = {
      date: selectedDate.date,
      registrationNumber: registrationNumber,
      comment: comment,
    };

    if (book === true) {
      await apiCall({
        url: '/parking-slots/book',
        method: 'POST',
        body,
        onSuccess: () => {
          toast({
            title: 'Your seat has been successfully booked',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'bottom',
            bg: 'brand.600',
          });
        },
      });
    } else {
      await apiCall({
        url: '/parking-slots/release',
        method: 'POST',
        body,
      });
    }
    fetchData();
    fetchTimeData();
  };

  const [selectedDate, setDate] = React.useState(null);
  const [registrationNumber, setRegistrationNumber] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [releaseDateTime, setReleaseTime] = useState(null);
  const handleInputChange = event => {
    setRegistrationNumber(event.target.value);
  };
  const handleInputChangeComment = event => {
    setComment(event.target.value);
  };
  const handleSelect = selectInfo => {
    console.log(selectInfo);
    const event = events.find(x => x.date === selectInfo.startStr);
    if (event !== undefined) {
      const selectedDateTime = new Date(selectInfo.startStr).getTime();
      const currentDateTime = new Date().getTime();
      if (selectedDateTime < currentDateTime && !isToday(selectedDateTime)) {
        setDate({
          date: selectInfo.startStr,
          isBooked: event.isBooked,
          isPastDate: true,
        });
      } else {
        setDate({
          date: selectInfo.startStr,
          isBooked: event.isBooked,
          isPastDate: false,
        });
      }
    } else {
      setDate(null);
    }
  };
  const isToday = dateTime => {
    const today = new Date();
    const selectedDate = new Date(dateTime);
    return (
      today.getDate() === selectedDate.getDate() &&
      today.getMonth() === selectedDate.getMonth() &&
      today.getFullYear() === selectedDate.getFullYear()
    );
  };

  const isDateFromTodayToReleaseTime = () => {
    if (!selectedDate || !releaseDateTime) return false;
    const selectedDateTime = new Date(selectedDate.date);
    const currentDateTime = new Date();
    selectedDateTime.setHours(currentDateTime.getHours(), currentDateTime.getMinutes());
    if ( selectedDateTime.toDateString() === releaseDateTime.toDateString())  {
      return selectedDateTime.getTime() > releaseDateTime.getTime() && !selectedDate.isPastDate;
    }
    return !(selectedDateTime.getTime() > releaseDateTime.getTime());
  };

  const events = data.map(x => mapSlot(x));
  return (
    <Box>
      <VStack spacing={4}>
        <Heading display={{ base: 'none', sm: 'block' }}>Free places</Heading>
        <CustomCalendar
          events={events}
          eventBackgroundColor={theme.colors.green['400']}
          plugins={[dayGridPlugin, interactionPlugin]}
          select={handleSelect}
          selectAllow={selectInfo =>
            selectInfo.end - selectInfo.start === oneDayMs
          }
        />
        {isDateFromTodayToReleaseTime() && (
          <Container>
            <VStack align="stretch">
              <Input
                hidden={selectedDate.isBooked || selectedDate.isPastDate}
                placeholder="Enter your registration number"
                value={registrationNumber}
                onChange={handleInputChange}
              />
              <Input
                hidden={selectedDate.isBooked || selectedDate.isPastDate}
                placeholder="Add comment (optional)"
                value={comment}
                onChange={handleInputChangeComment}
              />
              <HStack spacing={4} align="center">
                <Button
                  width="100%"
                  hidden={selectedDate.isBooked || selectedDate.isPastDate}
                  onClick={() => onAction()}
                  colorScheme="brand"
                  size="md"
                  isDisabled={!registrationNumber}
                >
                  Book Place
                </Button>
                <Button
                  width="100%"
                  hidden={!selectedDate.isBooked || selectedDate.isPastDate}
                  onClick={() => onAction(false)}
                  colorScheme="green"
                  size="lg"
                >
                  Release
                </Button>
              </HStack>
            </VStack>
          </Container>
        )}
      </VStack>
    </Box>
  );
};